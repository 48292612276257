import React from 'react';
import moment from 'moment';

import './PageProperties.css';

const SELECT_PLACEHOLDER = 'All Violations';

const initialState = {
  images: [],
  selectedPropertyID: 0,
  cover: true,
  currentFilter: '',
  filters: [],
};

const reducer = (state, action) => {
  switch (action.type) {
    case 'change-selectedPropertyID':
      return { ...state, selectedPropertyID: action.propertyID.substring(0, action.propertyID.length - 5).replace(/[ ,.]/g, "").toUpperCase(), cover: true };
    case 'update-images':
      return { ...state, images: action.images };
    case 'toggle-cover':
      return { ...state, cover: !state.cover };
    case 'update-filters':
      return { ...state, filters: action.filters.sort() };
    case 'update-currentFilter':
      const finalFilter = action.filter === SELECT_PLACEHOLDER ? '' : action.filter;
      return { ...state, currentFilter: finalFilter, selectedPropertyID: 0 };
    default:
      return state;
  }
};

const PageProperties = (props) => {
  const [state, dispatch] = React.useReducer(reducer, initialState);
  const images = require.context('../assets/ccphotos', true);

  React.useEffect(() => {
    const filters = new Set();
    for (const property of Object.values(props.ccProperties)) {
      for (const propertyViolation of property) {
        const violations = propertyViolation['crviolatio'].split(', ');
        for (const violation of violations) {
          if (violation) {
            filters.add(violation);
          }
        }
      }
    }

    dispatch({ type: 'update-filters', filters: Array.from(filters) });
  }, [props.ccProperties]);

  const _getImages = () => {
    const allProperties = [];

    for (const properties of Object.values(props.ccProperties)) {
      allProperties.push(...properties);
    }

    console.log('allprops', allProperties);

    return allProperties.filter((property) => {
      return property['crviolatio'].includes(state.currentFilter);
    }).reduce((final, imageProperties) => {
      try {
        const image = images(`./${imageProperties['Image File Name(s)']}`);
        const name = imageProperties['address_1'].substring(0, imageProperties['address_1'].length - 5).replace(/[ ,.]/g, "").toUpperCase();
        final.push(
          <div key={`${name} ${imageProperties['datetime']}`} className={`image-crop ${state.selectedPropertyID === name ? 'selected' : ''}`}>
            <img
              src={image}
              width='100%'
              onClick={() => dispatch({ type: 'change-selectedPropertyID', propertyID: imageProperties['address_1']})} />
          </div>
        );
      } catch (error) {
      }

      return final;
    }, []);
  };

  const _getPropertyProperties = () => {
    if (state.selectedPropertyID === 0) {
      return <div className='pending-selection'>Select a Property</div>;
    }

    const property = props.ccProperties[state.selectedPropertyID][props.ccProperties[state.selectedPropertyID].length - 1];
    // const addressParts = property['address'].split(', ');
    // console.log(addressParts);
    return (
      <>
        <img width={'100%'} src={`https://api.mapbox.com/styles/v1/mapbox/streets-v11/static/pin-l-circle+DD0000(${property['x']},${property['y']})/${property['x']},${property['y']},14,0/350x250@2x?access_token=${process.env.REACT_APP_MapboxAccessToken}&`} />
        <div className='properties-info'>
          <h3>{property['address_1']}</h3>
          <p>{moment(property['datetime'], 'YYYY:MM:DD HH:mm:ss').format('dddd, MMMM Do YYYY, h:mm:ss a')}</p>
          <p>Details: {property['crviolatio']}</p>
          <h4>Atlanta Complaint Data</h4>
          <p>Date: {moment(property['CEdate']).format('dddd, MMMM Do YYYY')}</p>
          <p>ID: {property['CEcomplaintid']}</p>
          <p>Description: {property['CEdescriptio']}</p>
          <p>Status: {property['CEstatus']}</p>
          <p>Notes: {property['CEshort_note']}</p>
          <h4>Owner Data</h4>
          <p>Name: {property['owner']}</p>
          <p>Address: {property['owner address']}</p>
        </div>
      </>
    );
  };

  const _getCenterImage = () => {
    if (state.selectedPropertyID === 0) {
      return <div className='pending-selection'>Select a Property</div>
    }
    console.log(state.selectedPropertyID);
    const image = images(`./${props.ccProperties[state.selectedPropertyID][props.ccProperties[state.selectedPropertyID].length - 1]['Image File Name(s)']}`);
    return (
      <img
        onClick={() => dispatch({ type: 'toggle-cover' })}
        className={ state.cover ? 'photo-full' : 'photo' }
        src={image} />
    );
  };

  const _getFilters = () => {
    const actualFilter = [SELECT_PLACEHOLDER, ...state.filters];
    return actualFilter.map((name) => {
      return (
        <option key={name} value={name}>{name}</option>
      );
    });
  };

  return (
    <div className='properties-content'>
      <section className='photo-grid'>
        <div className='filter'>
          <select onChange={(event) => {
            dispatch({ type: 'update-currentFilter', filter: event.target.value });
          }}>
            {_getFilters()}
          </select>
        </div>
        {_getImages()}
      </section>
      <section className='photo-box'>
        {_getCenterImage()}
      </section>
      <section className='properties'>
        {_getPropertyProperties()}
      </section>
    </div>
  );
};

export default PageProperties;
