import React from 'react';

import ReactMapGL, { Source, Layer } from 'react-map-gl';
import 'mapbox-gl/dist/mapbox-gl.css';

import './PageTimeline.css';

const monthIDLetterMap = {
  1: 'J',
  2: 'F',
  3: 'M',
  4: 'A',
  5: 'M',
  6: 'J',
  7: 'J',
  8: 'A',
  9: 'S',
  10: 'O',
  11: 'N',
  12: 'D',
};

const initialState = {
  viewport: {
    width: '100%',
    height: '100%',
    zoom: 12,
    latitude: 33.7678,
    longitude: -84.4094,
  },
  play: true,
  months: [],
  selectedMonths: [0],
  intervalID: undefined,
  ctrlPressed: false,
};

const reducer = (state, action) => {
  switch (action.type) {
    case 'update-viewport':
      return { ...state, viewport: action.viewport };
    case 'add-selectedMonths':
      let selectedMonths = [];
      if (state.ctrlPressed) {
        if (state.selectedMonths.includes(action.changeIndex)) {
          const monthsSet = new Set(state.selectedMonths);
          monthsSet.delete(action.changeIndex);
          selectedMonths = [...monthsSet];
        } else {
          selectedMonths = [...state.selectedMonths, action.changeIndex];
        }
      } else {
        selectedMonths = [action.changeIndex];
      }

      clearInterval(state.intervalID);
      return { ...state, selectedMonths, intervalID: undefined, play: false };
    case 'update-selectedMonths':
      clearInterval(state.intervalID);
      return { ...state, selectedMonths: action.selectedMonths, intervalID: undefined, play: false };
    case 'increment-month':
      return { ...state, selectedMonths: [(state.selectedMonths[0] + 1) % state.months.length] };
    case 'update-months':
      return { ...state, months: action.months };
    case 'toggle-play':
      return { ...state, play: !state.play };
    case 'change-interval':
      return { ...state, intervalID: action.intervalID };
    case 'clear-interval':
        clearInterval(state.intervalID);
      return { ...state, intervalID: undefined };
    case 'change-ctrlPressed':
      return { ...state, ctrlPressed: action.pressed };
    default:
      return state;
  }
};

const PageTimeline = (props) => {
  const [state, dispatch] = React.useReducer(reducer, initialState);

  React.useEffect(() => {
    const months = [];
    Object.keys(props.violationsByMonth).map((yearID) => {
      Object.keys(props.violationsByMonth[yearID]).map((monthID) => {
        months.push(['' + yearID, '' + monthID]);
      });
    });
    dispatch({ type: 'update-months', months });
    console.log(months);
  }, [props.violationsByMonth]);

  const startTimer = (intervalID) => {
    if (intervalID) {
      return intervalID;
    }

    const newIntervalID = setInterval(() => {
      dispatch({ type: 'increment-month' });
    }, 3000);

    dispatch({ type: 'change-interval', intervalID: newIntervalID });
    return newIntervalID;
  };

  const stopTimer = () => {
    dispatch({ type: 'clear-interval' });
  };

  React.useEffect(() => {
    const keydown = (event) => {
      if (event.key === 'Control' && !state.ctrlPressed) {
        dispatch({ type: 'change-ctrlPressed', pressed: true });
      }
    };

    const keyup = (event) => {
      dispatch({ type: 'change-ctrlPressed', pressed: false });
    }

    startTimer(state.intervalID);
    document.addEventListener('keydown', keydown);
    document.addEventListener('keyup', keyup);

    return () => {
      document.removeEventListener('keydown', keydown);
      document.removeEventListener('keyup', keyup);
      stopTimer();
    };
  }, []);

  const _getControls = () => {
    const monthRow = state.months.map((yearmonth, index) => {
      const [yearID, monthID] = yearmonth;
        return (
          <td
            key={`${yearID} ${monthID}`}
            onClick={() => dispatch({ type: 'add-selectedMonths', changeIndex: index })}
            width={20}
            align={'center'}
            className={state.selectedMonths.includes(index) ? 'selected' : '' }>
            {monthIDLetterMap[monthID]}
          </td>
        );
    });

    const yearRow = Object.keys(props.violationsByMonth).map((yearID) => {
      const numberMonths = Object.keys(props.violationsByMonth[yearID]).length;
      return (
        <th key={yearID} colSpan={numberMonths} onClick={() => {
          const selectedMonths = [];
          for (let i = 0; i < state.months.length; i++) {
            if (state.months[i][0] === '' + yearID) {
              selectedMonths.push(i);
            }
          }
          dispatch({ type: 'update-selectedMonths', selectedMonths });
        }}>{yearID}</th>
      );
    });

    const _getButton = () => {
      return state.play
        ? <i className='material-icons md-48' onClick={() => stopTimer()}>pause</i>
        : <i className='material-icons md-48' onClick={() => startTimer()}>play_arrow</i>;
    };

    return (
      <div className='month-slider'>
        <div className='pause-button-container' onClick={() => dispatch({ type: 'toggle-play' })}>
          <div className='pause-button'>
            {_getButton()}
          </div>
        </div>
        <table>
          <thead>
            <tr>{yearRow}</tr>
          </thead>
          <tbody>
            <tr>{monthRow}</tr>
          </tbody>
        </table>
      </div>
    );
  };
  
  const _getMarkers = () => {
    if (!props.violationsByMonth
      || state.months.length === 0) {
      return null;
    }

    const features = [];

    for (const month of state.selectedMonths) {
      for (const violation of props.violationsByMonth[state.months[month][0]][state.months[month][1]]) {
        features.push({
          type: 'Feature',
          properties: {
            address: violation['Address'],
            value: 1,
          },
          geometry: {
            type: 'Point',
            coordinates: [violation['Lon'], violation['Lat']],
          },
        });
      }
    }

    const geojson = {
      type: 'FeatureCollection',
      features,
    };
    
    return (
      <Source id='atlanta-violations' type='geojson' data={geojson}>
        <Layer
          id='property-point'
          type='circle'
          minzoom={10}
          paint={{
            "circle-radius": [
              "interpolate",
              ["linear"],
              ["zoom"],
              7, [
                "interpolate",
                ["linear"],
                ["get", "value"],
                1, 1,
                6, 4
              ],
              16, [
                "interpolate",
                ["linear"],
                ["get", "value"],
                1, 5,
                6, 50
              ]
            ],
              // Transition from heatmap to circle layer by zoom level
            "circle-opacity": [
              "interpolate",
              ["linear"],
              ["zoom"],
              14, 0,
              16, 1
            ]
          }}
        />
        <Layer
          id='property-heat'
          type='heatmap'
          paint={{
            'heatmap-weight': [
              'interpolate',
              ['linear'],
              ['get', 'value'],
              0, 0,
              6, 1
            ],
            'heatmap-intensity': [
              'interpolate',
              ['linear'],
              ['zoom'],
              9, 1,
              16, 3,
            ],
            'heatmap-color': [
              'interpolate',
              ['linear'],
              ['heatmap-density'],
              0, "rgba(33,102,172,0)",
              0.2, "rgb(103,169,207)",
              0.4, "rgb(209,229,240)",
              0.6, "rgb(253,219,199)",
              0.8, "rgb(239,138,98)",
              1, "rgb(178,24,43)",
            ],
            'heatmap-radius': [
              'interpolate',
              ['linear'],
              ['zoom'],
              0, 2,
              9, 20,
            ],
            'heatmap-opacity': [
              'interpolate',
              ['linear'],
              ['zoom'],
              14, 1,
              16, 0,
            ],
          }} />
      </Source>
    );
  };

  return (
    <div className='timeline-content'>
      <div className='map'>
        <ReactMapGL
          {...state.viewport}
          style={{
            position: 'absolute',
            top: 0,
            bottom: 0,
            // width: '100%',
            height: '100%',
          }}
          onViewportChange={(viewport) => {
            dispatch({ type: 'update-viewport', viewport })
          }}
          onResize={(dimensions) => {
            console.log(dimensions);
            dispatch({type: 'update-viewport', viewport: { ...state.viewport, ...dimensions}})
          }}
          mapboxApiAccessToken={process.env.REACT_APP_MapboxAccessToken}>
          {_getMarkers()}
        </ReactMapGL>
      </div>
      <div className='control'>
        {_getControls()}
      </div>
    </div>
  );
};

export default PageTimeline;
