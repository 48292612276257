import Axios from 'axios';
import Papa from 'papaparse';
import React from 'react';
import { MemoryRouter as Router, Route, Switch, Redirect }  from 'react-router-dom';
import 'mapbox-gl/dist/mapbox-gl.css';

import PageTimeline from './components/PageTimeline';
import PageEnglishAvenue from './components/PageEnglishAvenue';
import PageProperties from './components/PageProperties';
import PageHome from './components/PageHome';
import Navigation from './components/Navigation';

import codeviolations from './assets/codeviolations.csv';
import carefulcoding from './assets/carefulcoding2.csv';
import './App.css';

const initialState = {
  ccProperties: {},
  violationsByMonth: {},
  violationsEA: [],
};

const reducer = (state, action) => {
  switch (action.type) {
    case 'update-ccProperties':
      return { ...state, ccProperties: action.ccProperties };
    case 'update-violationsByMonth':
      return { ...state, violationsByMonth: action.violationsByMonth };
    case 'update-violationsEA':
      return { ...state, violationsEA: action.violationsEA };
    default:
      return state;
  }
};

const App = () => {
  const [state, dispatch] = React.useReducer(reducer, initialState);

  React.useEffect(() => {
    const _deriveViolationsByMonth = (violations) => {
      const violationsByMonth = {};
      for (const violation of violations) {
        const complaintNumberParts = violation['Date'].split('/');
        const month = parseInt(complaintNumberParts[0]);
        const year = parseInt(complaintNumberParts[2]);
  
        if (month > 0 && month <= 12) {
          if (!violationsByMonth[year]) {
            violationsByMonth[year] = {};
          }

          if (violationsByMonth[year][month]) {
            violationsByMonth[year][month].push(violation);
          } else {
            violationsByMonth[year][month] = [violation];
          }
        }
      }
  
      console.log(violationsByMonth);
      dispatch({ type: 'update-violationsByMonth', violationsByMonth });
    };

    const _deriveViolationsEA = (violations) => {
      const violationsEA = {};
      for (const violation of violations) {
        const lat = parseFloat(violation['Lat']);
        const lon = parseFloat(violation['Lon']);
        
        if (lon > -84.4176 && lon < -84.402884) {
          if (lat > 33.763429 && lat < 33.773111) {
            const canonAddress = violation['Address'].replace(/[ ,.]/g, "").toUpperCase();
            if (!violationsEA[canonAddress]) {
              violationsEA[canonAddress] = [];
            }

            violationsEA[canonAddress].push(violation);
          }
        }
      }
      dispatch({ type: 'update-violationsEA', violationsEA });
    }
  
    const fetchCodeViolations = async () => {
      const codeViolationsFile = await Axios.get(codeviolations);
      Papa.parse(codeViolationsFile.data, {
        delimiter: ',',
        header: true,
        skipEmptyLines: true,
        complete: (results, file) => {
          dispatch({ type: 'update-violations', violations: results.data });
          _deriveViolationsByMonth(results.data);
          _deriveViolationsEA(results.data);
        },
      });
    };

    const fetchCCProperties = async () => {
      const ccFile = await Axios.get(carefulcoding);
      Papa.parse(ccFile.data, {
        delimiter: ',',
        header: true,
        skipEmptyLines: true,
        complete: (results, file) => {
          const ccProperties = {};
          for (const property of results.data) {
            const addressKey = property['address_1'].substring(0, property['address_1'].length - 5).replace(/[ ,.]/g, "").toUpperCase();
            if (!ccProperties[addressKey]) {
              ccProperties[addressKey] = [];
            }
            ccProperties[addressKey].push(property);
          }
          console.log(ccProperties);
          dispatch({ type: 'update-ccProperties', ccProperties });
        },
      });
    };
  
    fetchCodeViolations();
    fetchCCProperties();
  }, []);

  return (
    <div className='content'>
      <Router>
        <Navigation />
        <Switch>
          <Route path='/timeline' render={() => <PageTimeline violationsByMonth={state.violationsByMonth} />} />
          <Route path='/english-avenue' render={() => <PageEnglishAvenue violationsEA={state.violationsEA} ccProperties={state.ccProperties} />} />
          <Route path='/properties' render={() => <PageProperties ccProperties={state.ccProperties} />} />
          <Route exact path='/' component={PageHome} />
          <Redirect to='/' />
        </Switch>
      </Router>
    </div>
  );
}

export default App;
