import React from 'react';
import ReactMapGL, { Source, Layer, Popup } from 'react-map-gl';

import './PageEnglishAvenue.css';

const initialState = {
  viewport: {
    width: '100%',
    height: '100%',
    zoom: 15,
    latitude: 33.7678,
    longitude: -84.4114,
  },
  popup: {
    latitude: 0,
    longitude: 0,
  },
  selectedPropertyID: undefined,
  coordinates: [],
  show: {
    atlanta: true,
    both: true,
    bbb: true,
  },
};

const reducer = (state, action) => {
  switch (action.type) {
    case 'update-viewport':
      return { ...state, viewport: action.viewport };
    case 'update-coordinates':
      return { ...state, coordinates: action.coordinates };
    case 'update-popup':
      return { ...state, popup: action.popup, selectedPropertyID: action.selectedPropertyID };
    case 'update-show':
      return { ...state, show: { ...state.show, ...action.show } };
    default:
      return state;
  }
};

const PageEnglishAvenue = (props) => {
  const [state, dispatch] = React.useReducer(reducer, initialState);

  React.useEffect(() => {
    const coordinates = Object.values(props.violationsEA).map((violationsAtAddress) => {
      const firstViolation = violationsAtAddress[0];
      return [firstViolation['Lon'], firstViolation['Lat']];
    });
    dispatch({ type: 'update-coordinates', coordinates })
  }, [props.violationsEA]);

  const _getMarkers = () => {
    if (!props.violationsEA || !props.ccProperties) {
      return null;
    }

    const atlantaFeatures = [];
    const bothFeatures = [];
    const ccFeatures = [];

    for (const address of Object.keys(props.violationsEA)) {
      const firstViolation = props.violationsEA[address][0];
      const feature = {
        type: 'Feature',
        properties: {
          address
        },
        geometry: {
          type: 'Point',
          coordinates: [firstViolation['Lon'], firstViolation['Lat']],
        },
      };
      if (props.ccProperties[address]) {
        bothFeatures.push(feature);
      } else {
        atlantaFeatures.push(feature);
      }
    }

    for (const address of Object.keys(props.ccProperties)) {
      const firstProperty = props.ccProperties[address][0];
      const feature = {
        type: 'Feature',
        properties: {
          address
        },
        geometry: {
          type: 'Point',
          coordinates: [firstProperty['x'], firstProperty['y']],
        },
      };
      if (!props.violationsEA[address] || (props.violationsEA[address] && props.violationsEA[address].length === 0)) {
        ccFeatures.push(feature);
      }
    }

    if (atlantaFeatures.length === 0 || bothFeatures.length === 0 || ccFeatures.length === 0) {
      return null;
    }

    const atlantaGeojson = {
      type: 'FeatureCollection',
      features: atlantaFeatures,
    };

    const bothGeojson = {
      type: 'FeatureCollection',
      features: bothFeatures,
    };
    const ccGeojson = {
      type: 'FeatureCollection',
      features: ccFeatures,
    };
    
    return (
      <div>
        { state.show.bbb
          ? <Source id='cc-violations' type='geojson' data={ccGeojson}>
              <Layer
                id='cc-point'
                type='circle'
                paint={{
                  'circle-radius': 6,
                  'circle-color': '#00AEDB',
                }} />
            </Source>
          : null
        }
        { state.show.both
          ? <Source id='both-violations' type='geojson' data={bothGeojson}>
              <Layer
                id='both-point'
                type='circle'
                paint={{
                  'circle-radius': 6,
                  'circle-color': '#FFC425',
                }} />
            </Source>
          : null
        }
        { state.show.atlanta
          ? <Source id='atlanta-violations' type='geojson' data={atlantaGeojson}>
              <Layer
                id='atlanta-point'
                type='circle'
                paint={{
                  'circle-radius': 6,
                  'circle-color': '#D11141',
                }} />
            </Source>
          : null
        }
      </div>
    );
  };

  const _getPopup = () => {
    if (!state.selectedPropertyID) {
      return null;
    }

    const violationEA = props.violationsEA[state.selectedPropertyID] ? props.violationsEA[state.selectedPropertyID][0] : undefined;
    const ccProperty = props.ccProperties[state.selectedPropertyID] ? props.ccProperties[state.selectedPropertyID][0] : undefined;

    const _getAtlantaData = () => {
      if (!violationEA) {
        return 'No Data Available';
      }

      return <div className='popup'>
        <p>{violationEA['Date']}</p>
        <p>Complaint ID: {violationEA['Complaint Number']}</p>
        <p>Complaint Description: {violationEA['Description']}</p>
        <p>Complaint Status: {violationEA['Status']}</p>
      </div>;
    };
    
    const _getBBBData = () => {
      if (!ccProperty) {
        return 'No Data Available';
      }

      return <div className='popup'>
        <p>{ccProperty['datetime']}</p>
        <p>Complaint ID: {ccProperty['complaintid']}</p>
        <p>Complaint Description: {ccProperty['descriptio']}</p>
        <p>Complaint Status: {ccProperty['status']}</p>
        <p>Owner: {ccProperty['owner']}</p>
        <p>Owner Address: {ccProperty['owner address']}</p>
      </div>;
    };

    return <Popup
      {...state.popup}
      closeButton={true}
      closeOnClick={true}
      onClose={() => dispatch({ type: 'update-popup', popup: state.popup, selectedPropertyID: undefined })} >
      <div>
        <h3>{violationEA ? violationEA['Address'] : ccProperty['address_1']}</h3>
        <h4>Atlanta Data</h4>
        {_getAtlantaData()}
        <h4>Block-by-Block Data</h4>
        {_getBBBData()}
      </div>
    </Popup>
  };

  const handleLegendItemClick = (which) => {
    dispatch({ type: 'update-show', show: { [which]: !state.show[which] }});
  };

  return (
    <div className='ea-content'>
      <div className='map'>
        <ReactMapGL
          {...state.viewport}
          maxZoom={20}
          onClick={(event) => {
            const featureList = [];
            for (const feature of event.features) {
              if (feature.layer.id !== 'building') {
                featureList.push(feature);
              }
            }

            if (featureList.length > 0) {
              const address = featureList[0].properties.address;
              const violationEA = props.violationsEA[address] ? props.violationsEA[address][0] : undefined;
              const ccProperty = props.ccProperties[address] ? props.ccProperties[address][0] : undefined;
              console.log(ccProperty);
              console.log(violationEA);
              if (violationEA || ccProperty) {
                dispatch({
                  type: 'update-popup',
                  popup: {
                    latitude: violationEA ? parseFloat(violationEA['Lat']) : parseFloat(ccProperty['y']),
                    longitude: violationEA ? parseFloat(violationEA['Lon']) : parseFloat(ccProperty['x']),
                  },
                  selectedPropertyID: address,
                });
              }
            } 
          }}
          onViewportChange={(viewport) => dispatch({ type: 'update-viewport', viewport })}
          style={{
            position: 'absolute',
            top: 0,
            bottom: 0,
            width: '100%',
            height: '100%',
          }}
          mapboxApiAccessToken={process.env.REACT_APP_MapboxAccessToken}>
          {_getMarkers()}
          {_getPopup()}
        </ReactMapGL>
      </div>
      <div className='legend'>
        <div className='legend-item' onClick={() => handleLegendItemClick('atlanta')} >
          <div className={`legend-item-icon red ${state.show.atlanta ? '' : 'unfilled' }`}/>
          Atlanta Only
        </div>
        <div className='legend-item' onClick={() => handleLegendItemClick('both')} >
          <div className={`legend-item-icon yellow ${state.show.both ? '' : 'unfilled' }`}/>
          Atlanta &amp; Block-By-Block
        </div>
        <div className='legend-item' onClick={() => handleLegendItemClick('bbb')} >
          <div className={`legend-item-icon blue ${state.show.bbb ? '' : 'unfilled' }`}/>
          Block-By-Block Only
        </div>
      </div>
    </div>
  );
};

export default PageEnglishAvenue;
