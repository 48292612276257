import React from 'react';
import { Link } from 'react-router-dom';
import './PageHome.css';

const PageHome = (props) => {
  return (
    <div className='home-content'>
      <div className='home-text'>
        <p>The maps and visualizations on these pages display code violation data from the City of Atlanta and our partners in Block by Block, a neighborhood organization based in the English Avenue neighborhood of Atlanta.</p>
        
        <p>Code violations are infractions in the built environment, such as open and vacant structures, junk vehicles, and overgrown lots. These infractions occur on both residential and commercial properties and pertain to the rules and standards in the Atlanta Housing Code, Graffiti Ordinance, and/or Commercial Maintenance and Industrial Code. In Atlanta, code violations are documented and acted upon by the <a href='https://www.atlantapd.org/services/community-services/office-of-code-enforcement' target='_blank'> Code Enforcement Section of the Atlanta Police Department</a>.</p>

        <p>Code violations are often markers of broader issues, including the neglect that accompanies long-term systemic racism, classism, and oppression. These violations may also be markers of impending gentrification, as absentee and negligent landlords let properties deteriorate while they await a rise in the value of the land, and subsequently more affluent buyers and renters. Tracking and acting on code violations is a way to stem and address the negative effects of these histories and current conditions.</p>

        <p>In 2016 Block by Block began documenting code violations and tracking properties over time in the neighborhood, as a way to document the current conditions and collect data that could be used to care for English Ave and its long-term residents, by advocating to the City of Atlanta for attention and resources. As engaged researchers and designers, we have accompanied them in their efforts. This has included participating with them in data collection, designing tools and procedures to facilitate resident-led data collection, wrangling the data, working with Block by Block to advocate for their neighborhood through the data, and, producing maps, visualizations, and other representations of the data. The maps and visualizations on these pages are one set of outcomes from this research and design.</p>

        <p>On the <Link to='./timeline'>Timeline</Link> page is a map with code violation data for Atlanta, taken from the City of Atlanta database of Code Violations. This data is sorted month by month. You can play through the data over time, select multiple months, or entire years. It is worth exploring the data over time to note patterns, in geographic locations as well as by season.</p>

        <p>On the <Link to='./english-avenue'>English Avenue</Link>, page is a map with code violation data for the English Avenue neighborhood of Atlanta. The blue dots are violations that were listed in the City of Atlanta database. The red dots are properties tracked by Block by Block but are not in the City of Atlanta database. The yellow dots are violations that are both in the City of Atlanta database and properties tracked by Block by Block. Clicking on any dot provides information on that property, as collected by the City of Atlanta, and where available, as documented by Block by Block.</p>

        <p>On the <Link to='./properties'>Properties</Link> page is a collection of photographs taken by Block by Block volunteer data collectors of code violations in English Avenue. Clicking on an image provides a full-sized view, accompanied by the information on the property pictured, including the current owner and their address. You may quickly notice how many owners of properties with code violations do not live in the neighborhood.</p>

        <p>As of now, the data on this map is static and represents the state of affairs as of October 2019.</p>

        <p>We invite you to explore the data and the representations as a view into the occurrence of code violations in Atlanta, and as a demonstration of how resident collected data might be integrated with municipal data. </p>

        <p><strong>Project Leads:</strong> <a href='https://www.cc.gatech.edu/people/amanda-meng' target='_blank'>Amanda Meng</a> and Les Canty<br />
        <strong>Programming and Design:</strong> <a href='https://jamesjlu.com' target='_blank'>James Lu</a><br />
        <strong>Project Associates:</strong> <a href='https://www.iac.gatech.edu/people/faculty/disalvo' target='_blank'>Carl DiSalvo</a> and <a href='https://www.cc.gatech.edu/~ewz/Welcome.html' target='_blank'>Ellen Zegura</a></p>

        <p>For more information please contact Amanda Meng at <a href='mailto:a.meng@gatech.edu?subject=Block by Block Visualization Inquiry' target='_blank'> a.meng@gatech.edu</a>.</p>
      </div>
    </div>
  );
};

export default PageHome;
