import React from 'react';
import { NavLink } from 'react-router-dom';

import './Navigation.css';

const Navigation = (props) => {
  return (
    <div className='navigation'>
      <h1>Atlanta Code Violations</h1>
      <NavLink to='/' exact activeClassName='active'>Home</NavLink>
      <NavLink to='/timeline' activeClassName='active'>Timeline</NavLink>
      <NavLink to='/english-avenue' activeClassName='active'>English Avenue</NavLink>
      <NavLink to='/properties' activeClassName='active'>Properties</NavLink>
    </div>
  );
};

export default Navigation;
